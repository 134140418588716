import { jsx } from "react/jsx-runtime";
import { usePage } from "@tecnobit-srl/router";
import { createState } from "@tecnobit-srl/state";
import { use } from "i18next";
import { useEffect } from "react";
import { initReactI18next } from "react-i18next";
import { useLogger } from "@tecnobit-srl/dev-logger";
var freeGlobal = typeof global == "object" && global && global.Object === Object && global;
var freeSelf = typeof self == "object" && self && self.Object === Object && self;
var root = freeGlobal || freeSelf || Function("return this")();
var Symbol$1 = root.Symbol;
var objectProto$9 = Object.prototype;
var hasOwnProperty$7 = objectProto$9.hasOwnProperty;
var nativeObjectToString$1 = objectProto$9.toString;
var symToStringTag$1 = Symbol$1 ? Symbol$1.toStringTag : void 0;
function getRawTag(value) {
  var isOwn = hasOwnProperty$7.call(value, symToStringTag$1), tag = value[symToStringTag$1];
  try {
    value[symToStringTag$1] = void 0;
    var unmasked = true;
  } catch (e) {
  }
  var result = nativeObjectToString$1.call(value);
  if (unmasked) {
    if (isOwn) {
      value[symToStringTag$1] = tag;
    } else {
      delete value[symToStringTag$1];
    }
  }
  return result;
}
var objectProto$8 = Object.prototype;
var nativeObjectToString = objectProto$8.toString;
function objectToString(value) {
  return nativeObjectToString.call(value);
}
var nullTag = "[object Null]", undefinedTag = "[object Undefined]";
var symToStringTag = Symbol$1 ? Symbol$1.toStringTag : void 0;
function baseGetTag(value) {
  if (value == null) {
    return value === void 0 ? undefinedTag : nullTag;
  }
  return symToStringTag && symToStringTag in Object(value) ? getRawTag(value) : objectToString(value);
}
function isObjectLike(value) {
  return value != null && typeof value == "object";
}
var isArray = Array.isArray;
function isObject(value) {
  var type = typeof value;
  return value != null && (type == "object" || type == "function");
}
function identity(value) {
  return value;
}
var asyncTag = "[object AsyncFunction]", funcTag$1 = "[object Function]", genTag = "[object GeneratorFunction]", proxyTag = "[object Proxy]";
function isFunction(value) {
  if (!isObject(value)) {
    return false;
  }
  var tag = baseGetTag(value);
  return tag == funcTag$1 || tag == genTag || tag == asyncTag || tag == proxyTag;
}
var coreJsData = root["__core-js_shared__"];
var maskSrcKey = function() {
  var uid = /[^.]+$/.exec(coreJsData && coreJsData.keys && coreJsData.keys.IE_PROTO || "");
  return uid ? "Symbol(src)_1." + uid : "";
}();
function isMasked(func) {
  return !!maskSrcKey && maskSrcKey in func;
}
var funcProto$2 = Function.prototype;
var funcToString$2 = funcProto$2.toString;
function toSource(func) {
  if (func != null) {
    try {
      return funcToString$2.call(func);
    } catch (e) {
    }
    try {
      return func + "";
    } catch (e) {
    }
  }
  return "";
}
var reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
var reIsHostCtor = /^\[object .+?Constructor\]$/;
var funcProto$1 = Function.prototype, objectProto$7 = Object.prototype;
var funcToString$1 = funcProto$1.toString;
var hasOwnProperty$6 = objectProto$7.hasOwnProperty;
var reIsNative = RegExp(
  "^" + funcToString$1.call(hasOwnProperty$6).replace(reRegExpChar, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function baseIsNative(value) {
  if (!isObject(value) || isMasked(value)) {
    return false;
  }
  var pattern = isFunction(value) ? reIsNative : reIsHostCtor;
  return pattern.test(toSource(value));
}
function getValue(object, key) {
  return object == null ? void 0 : object[key];
}
function getNative(object, key) {
  var value = getValue(object, key);
  return baseIsNative(value) ? value : void 0;
}
var objectCreate = Object.create;
var baseCreate = /* @__PURE__ */ function() {
  function object() {
  }
  return function(proto) {
    if (!isObject(proto)) {
      return {};
    }
    if (objectCreate) {
      return objectCreate(proto);
    }
    object.prototype = proto;
    var result = new object();
    object.prototype = void 0;
    return result;
  };
}();
function apply(func, thisArg, args) {
  switch (args.length) {
    case 0:
      return func.call(thisArg);
    case 1:
      return func.call(thisArg, args[0]);
    case 2:
      return func.call(thisArg, args[0], args[1]);
    case 3:
      return func.call(thisArg, args[0], args[1], args[2]);
  }
  return func.apply(thisArg, args);
}
function copyArray(source, array) {
  var index = -1, length = source.length;
  array || (array = Array(length));
  while (++index < length) {
    array[index] = source[index];
  }
  return array;
}
var HOT_COUNT = 800, HOT_SPAN = 16;
var nativeNow = Date.now;
function shortOut(func) {
  var count = 0, lastCalled = 0;
  return function() {
    var stamp = nativeNow(), remaining = HOT_SPAN - (stamp - lastCalled);
    lastCalled = stamp;
    if (remaining > 0) {
      if (++count >= HOT_COUNT) {
        return arguments[0];
      }
    } else {
      count = 0;
    }
    return func.apply(void 0, arguments);
  };
}
function constant(value) {
  return function() {
    return value;
  };
}
var defineProperty = function() {
  try {
    var func = getNative(Object, "defineProperty");
    func({}, "", {});
    return func;
  } catch (e) {
  }
}();
var baseSetToString = !defineProperty ? identity : function(func, string) {
  return defineProperty(func, "toString", {
    "configurable": true,
    "enumerable": false,
    "value": constant(string),
    "writable": true
  });
};
var setToString = shortOut(baseSetToString);
var MAX_SAFE_INTEGER$1 = 9007199254740991;
var reIsUint = /^(?:0|[1-9]\d*)$/;
function isIndex(value, length) {
  var type = typeof value;
  length = length == null ? MAX_SAFE_INTEGER$1 : length;
  return !!length && (type == "number" || type != "symbol" && reIsUint.test(value)) && (value > -1 && value % 1 == 0 && value < length);
}
function baseAssignValue(object, key, value) {
  if (key == "__proto__" && defineProperty) {
    defineProperty(object, key, {
      "configurable": true,
      "enumerable": true,
      "value": value,
      "writable": true
    });
  } else {
    object[key] = value;
  }
}
function eq(value, other) {
  return value === other || value !== value && other !== other;
}
var objectProto$6 = Object.prototype;
var hasOwnProperty$5 = objectProto$6.hasOwnProperty;
function assignValue(object, key, value) {
  var objValue = object[key];
  if (!(hasOwnProperty$5.call(object, key) && eq(objValue, value)) || value === void 0 && !(key in object)) {
    baseAssignValue(object, key, value);
  }
}
function copyObject(source, props, object, customizer) {
  var isNew = !object;
  object || (object = {});
  var index = -1, length = props.length;
  while (++index < length) {
    var key = props[index];
    var newValue = void 0;
    if (newValue === void 0) {
      newValue = source[key];
    }
    if (isNew) {
      baseAssignValue(object, key, newValue);
    } else {
      assignValue(object, key, newValue);
    }
  }
  return object;
}
var nativeMax = Math.max;
function overRest(func, start, transform) {
  start = nativeMax(start === void 0 ? func.length - 1 : start, 0);
  return function() {
    var args = arguments, index = -1, length = nativeMax(args.length - start, 0), array = Array(length);
    while (++index < length) {
      array[index] = args[start + index];
    }
    index = -1;
    var otherArgs = Array(start + 1);
    while (++index < start) {
      otherArgs[index] = args[index];
    }
    otherArgs[start] = transform(array);
    return apply(func, this, otherArgs);
  };
}
function baseRest(func, start) {
  return setToString(overRest(func, start, identity), func + "");
}
var MAX_SAFE_INTEGER = 9007199254740991;
function isLength(value) {
  return typeof value == "number" && value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
}
function isArrayLike(value) {
  return value != null && isLength(value.length) && !isFunction(value);
}
function isIterateeCall(value, index, object) {
  if (!isObject(object)) {
    return false;
  }
  var type = typeof index;
  if (type == "number" ? isArrayLike(object) && isIndex(index, object.length) : type == "string" && index in object) {
    return eq(object[index], value);
  }
  return false;
}
function createAssigner(assigner) {
  return baseRest(function(object, sources) {
    var index = -1, length = sources.length, customizer = length > 1 ? sources[length - 1] : void 0, guard = length > 2 ? sources[2] : void 0;
    customizer = assigner.length > 3 && typeof customizer == "function" ? (length--, customizer) : void 0;
    if (guard && isIterateeCall(sources[0], sources[1], guard)) {
      customizer = length < 3 ? void 0 : customizer;
      length = 1;
    }
    object = Object(object);
    while (++index < length) {
      var source = sources[index];
      if (source) {
        assigner(object, source, index, customizer);
      }
    }
    return object;
  });
}
var objectProto$5 = Object.prototype;
function isPrototype(value) {
  var Ctor = value && value.constructor, proto = typeof Ctor == "function" && Ctor.prototype || objectProto$5;
  return value === proto;
}
function baseTimes(n, iteratee) {
  var index = -1, result = Array(n);
  while (++index < n) {
    result[index] = iteratee(index);
  }
  return result;
}
var argsTag$1 = "[object Arguments]";
function baseIsArguments(value) {
  return isObjectLike(value) && baseGetTag(value) == argsTag$1;
}
var objectProto$4 = Object.prototype;
var hasOwnProperty$4 = objectProto$4.hasOwnProperty;
var propertyIsEnumerable = objectProto$4.propertyIsEnumerable;
var isArguments = baseIsArguments(/* @__PURE__ */ function() {
  return arguments;
}()) ? baseIsArguments : function(value) {
  return isObjectLike(value) && hasOwnProperty$4.call(value, "callee") && !propertyIsEnumerable.call(value, "callee");
};
function stubFalse() {
  return false;
}
var freeExports$2 = typeof exports == "object" && exports && !exports.nodeType && exports;
var freeModule$2 = freeExports$2 && typeof module == "object" && module && !module.nodeType && module;
var moduleExports$2 = freeModule$2 && freeModule$2.exports === freeExports$2;
var Buffer$1 = moduleExports$2 ? root.Buffer : void 0;
var nativeIsBuffer = Buffer$1 ? Buffer$1.isBuffer : void 0;
var isBuffer = nativeIsBuffer || stubFalse;
var argsTag = "[object Arguments]", arrayTag = "[object Array]", boolTag = "[object Boolean]", dateTag = "[object Date]", errorTag = "[object Error]", funcTag = "[object Function]", mapTag = "[object Map]", numberTag = "[object Number]", objectTag$1 = "[object Object]", regexpTag = "[object RegExp]", setTag = "[object Set]", stringTag = "[object String]", weakMapTag = "[object WeakMap]";
var arrayBufferTag = "[object ArrayBuffer]", dataViewTag = "[object DataView]", float32Tag = "[object Float32Array]", float64Tag = "[object Float64Array]", int8Tag = "[object Int8Array]", int16Tag = "[object Int16Array]", int32Tag = "[object Int32Array]", uint8Tag = "[object Uint8Array]", uint8ClampedTag = "[object Uint8ClampedArray]", uint16Tag = "[object Uint16Array]", uint32Tag = "[object Uint32Array]";
var typedArrayTags = {};
typedArrayTags[float32Tag] = typedArrayTags[float64Tag] = typedArrayTags[int8Tag] = typedArrayTags[int16Tag] = typedArrayTags[int32Tag] = typedArrayTags[uint8Tag] = typedArrayTags[uint8ClampedTag] = typedArrayTags[uint16Tag] = typedArrayTags[uint32Tag] = true;
typedArrayTags[argsTag] = typedArrayTags[arrayTag] = typedArrayTags[arrayBufferTag] = typedArrayTags[boolTag] = typedArrayTags[dataViewTag] = typedArrayTags[dateTag] = typedArrayTags[errorTag] = typedArrayTags[funcTag] = typedArrayTags[mapTag] = typedArrayTags[numberTag] = typedArrayTags[objectTag$1] = typedArrayTags[regexpTag] = typedArrayTags[setTag] = typedArrayTags[stringTag] = typedArrayTags[weakMapTag] = false;
function baseIsTypedArray(value) {
  return isObjectLike(value) && isLength(value.length) && !!typedArrayTags[baseGetTag(value)];
}
function baseUnary(func) {
  return function(value) {
    return func(value);
  };
}
var freeExports$1 = typeof exports == "object" && exports && !exports.nodeType && exports;
var freeModule$1 = freeExports$1 && typeof module == "object" && module && !module.nodeType && module;
var moduleExports$1 = freeModule$1 && freeModule$1.exports === freeExports$1;
var freeProcess = moduleExports$1 && freeGlobal.process;
var nodeUtil = function() {
  try {
    var types = freeModule$1 && freeModule$1.require && freeModule$1.require("util").types;
    if (types) {
      return types;
    }
    return freeProcess && freeProcess.binding && freeProcess.binding("util");
  } catch (e) {
  }
}();
var nodeIsTypedArray = nodeUtil && nodeUtil.isTypedArray;
var isTypedArray = nodeIsTypedArray ? baseUnary(nodeIsTypedArray) : baseIsTypedArray;
function arrayLikeKeys(value, inherited) {
  var isArr = isArray(value), isArg = !isArr && isArguments(value), isBuff = !isArr && !isArg && isBuffer(value), isType = !isArr && !isArg && !isBuff && isTypedArray(value), skipIndexes = isArr || isArg || isBuff || isType, result = skipIndexes ? baseTimes(value.length, String) : [], length = result.length;
  for (var key in value) {
    if (!(skipIndexes && // Safari 9 has enumerable `arguments.length` in strict mode.
    (key == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    isBuff && (key == "offset" || key == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    isType && (key == "buffer" || key == "byteLength" || key == "byteOffset") || // Skip index properties.
    isIndex(key, length)))) {
      result.push(key);
    }
  }
  return result;
}
function overArg(func, transform) {
  return function(arg) {
    return func(transform(arg));
  };
}
function nativeKeysIn(object) {
  var result = [];
  if (object != null) {
    for (var key in Object(object)) {
      result.push(key);
    }
  }
  return result;
}
var objectProto$3 = Object.prototype;
var hasOwnProperty$3 = objectProto$3.hasOwnProperty;
function baseKeysIn(object) {
  if (!isObject(object)) {
    return nativeKeysIn(object);
  }
  var isProto = isPrototype(object), result = [];
  for (var key in object) {
    if (!(key == "constructor" && (isProto || !hasOwnProperty$3.call(object, key)))) {
      result.push(key);
    }
  }
  return result;
}
function keysIn(object) {
  return isArrayLike(object) ? arrayLikeKeys(object) : baseKeysIn(object);
}
var nativeCreate = getNative(Object, "create");
function hashClear() {
  this.__data__ = nativeCreate ? nativeCreate(null) : {};
  this.size = 0;
}
function hashDelete(key) {
  var result = this.has(key) && delete this.__data__[key];
  this.size -= result ? 1 : 0;
  return result;
}
var HASH_UNDEFINED$1 = "__lodash_hash_undefined__";
var objectProto$2 = Object.prototype;
var hasOwnProperty$2 = objectProto$2.hasOwnProperty;
function hashGet(key) {
  var data = this.__data__;
  if (nativeCreate) {
    var result = data[key];
    return result === HASH_UNDEFINED$1 ? void 0 : result;
  }
  return hasOwnProperty$2.call(data, key) ? data[key] : void 0;
}
var objectProto$1 = Object.prototype;
var hasOwnProperty$1 = objectProto$1.hasOwnProperty;
function hashHas(key) {
  var data = this.__data__;
  return nativeCreate ? data[key] !== void 0 : hasOwnProperty$1.call(data, key);
}
var HASH_UNDEFINED = "__lodash_hash_undefined__";
function hashSet(key, value) {
  var data = this.__data__;
  this.size += this.has(key) ? 0 : 1;
  data[key] = nativeCreate && value === void 0 ? HASH_UNDEFINED : value;
  return this;
}
function Hash(entries) {
  var index = -1, length = entries == null ? 0 : entries.length;
  this.clear();
  while (++index < length) {
    var entry = entries[index];
    this.set(entry[0], entry[1]);
  }
}
Hash.prototype.clear = hashClear;
Hash.prototype["delete"] = hashDelete;
Hash.prototype.get = hashGet;
Hash.prototype.has = hashHas;
Hash.prototype.set = hashSet;
function listCacheClear() {
  this.__data__ = [];
  this.size = 0;
}
function assocIndexOf(array, key) {
  var length = array.length;
  while (length--) {
    if (eq(array[length][0], key)) {
      return length;
    }
  }
  return -1;
}
var arrayProto = Array.prototype;
var splice = arrayProto.splice;
function listCacheDelete(key) {
  var data = this.__data__, index = assocIndexOf(data, key);
  if (index < 0) {
    return false;
  }
  var lastIndex = data.length - 1;
  if (index == lastIndex) {
    data.pop();
  } else {
    splice.call(data, index, 1);
  }
  --this.size;
  return true;
}
function listCacheGet(key) {
  var data = this.__data__, index = assocIndexOf(data, key);
  return index < 0 ? void 0 : data[index][1];
}
function listCacheHas(key) {
  return assocIndexOf(this.__data__, key) > -1;
}
function listCacheSet(key, value) {
  var data = this.__data__, index = assocIndexOf(data, key);
  if (index < 0) {
    ++this.size;
    data.push([key, value]);
  } else {
    data[index][1] = value;
  }
  return this;
}
function ListCache(entries) {
  var index = -1, length = entries == null ? 0 : entries.length;
  this.clear();
  while (++index < length) {
    var entry = entries[index];
    this.set(entry[0], entry[1]);
  }
}
ListCache.prototype.clear = listCacheClear;
ListCache.prototype["delete"] = listCacheDelete;
ListCache.prototype.get = listCacheGet;
ListCache.prototype.has = listCacheHas;
ListCache.prototype.set = listCacheSet;
var Map = getNative(root, "Map");
function mapCacheClear() {
  this.size = 0;
  this.__data__ = {
    "hash": new Hash(),
    "map": new (Map || ListCache)(),
    "string": new Hash()
  };
}
function isKeyable(value) {
  var type = typeof value;
  return type == "string" || type == "number" || type == "symbol" || type == "boolean" ? value !== "__proto__" : value === null;
}
function getMapData(map, key) {
  var data = map.__data__;
  return isKeyable(key) ? data[typeof key == "string" ? "string" : "hash"] : data.map;
}
function mapCacheDelete(key) {
  var result = getMapData(this, key)["delete"](key);
  this.size -= result ? 1 : 0;
  return result;
}
function mapCacheGet(key) {
  return getMapData(this, key).get(key);
}
function mapCacheHas(key) {
  return getMapData(this, key).has(key);
}
function mapCacheSet(key, value) {
  var data = getMapData(this, key), size = data.size;
  data.set(key, value);
  this.size += data.size == size ? 0 : 1;
  return this;
}
function MapCache(entries) {
  var index = -1, length = entries == null ? 0 : entries.length;
  this.clear();
  while (++index < length) {
    var entry = entries[index];
    this.set(entry[0], entry[1]);
  }
}
MapCache.prototype.clear = mapCacheClear;
MapCache.prototype["delete"] = mapCacheDelete;
MapCache.prototype.get = mapCacheGet;
MapCache.prototype.has = mapCacheHas;
MapCache.prototype.set = mapCacheSet;
var getPrototype = overArg(Object.getPrototypeOf, Object);
var objectTag = "[object Object]";
var funcProto = Function.prototype, objectProto = Object.prototype;
var funcToString = funcProto.toString;
var hasOwnProperty = objectProto.hasOwnProperty;
var objectCtorString = funcToString.call(Object);
function isPlainObject(value) {
  if (!isObjectLike(value) || baseGetTag(value) != objectTag) {
    return false;
  }
  var proto = getPrototype(value);
  if (proto === null) {
    return true;
  }
  var Ctor = hasOwnProperty.call(proto, "constructor") && proto.constructor;
  return typeof Ctor == "function" && Ctor instanceof Ctor && funcToString.call(Ctor) == objectCtorString;
}
function stackClear() {
  this.__data__ = new ListCache();
  this.size = 0;
}
function stackDelete(key) {
  var data = this.__data__, result = data["delete"](key);
  this.size = data.size;
  return result;
}
function stackGet(key) {
  return this.__data__.get(key);
}
function stackHas(key) {
  return this.__data__.has(key);
}
var LARGE_ARRAY_SIZE = 200;
function stackSet(key, value) {
  var data = this.__data__;
  if (data instanceof ListCache) {
    var pairs = data.__data__;
    if (!Map || pairs.length < LARGE_ARRAY_SIZE - 1) {
      pairs.push([key, value]);
      this.size = ++data.size;
      return this;
    }
    data = this.__data__ = new MapCache(pairs);
  }
  data.set(key, value);
  this.size = data.size;
  return this;
}
function Stack(entries) {
  var data = this.__data__ = new ListCache(entries);
  this.size = data.size;
}
Stack.prototype.clear = stackClear;
Stack.prototype["delete"] = stackDelete;
Stack.prototype.get = stackGet;
Stack.prototype.has = stackHas;
Stack.prototype.set = stackSet;
var freeExports = typeof exports == "object" && exports && !exports.nodeType && exports;
var freeModule = freeExports && typeof module == "object" && module && !module.nodeType && module;
var moduleExports = freeModule && freeModule.exports === freeExports;
var Buffer = moduleExports ? root.Buffer : void 0;
Buffer ? Buffer.allocUnsafe : void 0;
function cloneBuffer(buffer, isDeep) {
  {
    return buffer.slice();
  }
}
var Uint8Array = root.Uint8Array;
function cloneArrayBuffer(arrayBuffer) {
  var result = new arrayBuffer.constructor(arrayBuffer.byteLength);
  new Uint8Array(result).set(new Uint8Array(arrayBuffer));
  return result;
}
function cloneTypedArray(typedArray, isDeep) {
  var buffer = cloneArrayBuffer(typedArray.buffer);
  return new typedArray.constructor(buffer, typedArray.byteOffset, typedArray.length);
}
function initCloneObject(object) {
  return typeof object.constructor == "function" && !isPrototype(object) ? baseCreate(getPrototype(object)) : {};
}
function createBaseFor(fromRight) {
  return function(object, iteratee, keysFunc) {
    var index = -1, iterable = Object(object), props = keysFunc(object), length = props.length;
    while (length--) {
      var key = props[++index];
      if (iteratee(iterable[key], key, iterable) === false) {
        break;
      }
    }
    return object;
  };
}
var baseFor = createBaseFor();
function assignMergeValue(object, key, value) {
  if (value !== void 0 && !eq(object[key], value) || value === void 0 && !(key in object)) {
    baseAssignValue(object, key, value);
  }
}
function isArrayLikeObject(value) {
  return isObjectLike(value) && isArrayLike(value);
}
function safeGet(object, key) {
  if (key === "constructor" && typeof object[key] === "function") {
    return;
  }
  if (key == "__proto__") {
    return;
  }
  return object[key];
}
function toPlainObject(value) {
  return copyObject(value, keysIn(value));
}
function baseMergeDeep(object, source, key, srcIndex, mergeFunc, customizer, stack) {
  var objValue = safeGet(object, key), srcValue = safeGet(source, key), stacked = stack.get(srcValue);
  if (stacked) {
    assignMergeValue(object, key, stacked);
    return;
  }
  var newValue = customizer ? customizer(objValue, srcValue, key + "", object, source, stack) : void 0;
  var isCommon = newValue === void 0;
  if (isCommon) {
    var isArr = isArray(srcValue), isBuff = !isArr && isBuffer(srcValue), isTyped = !isArr && !isBuff && isTypedArray(srcValue);
    newValue = srcValue;
    if (isArr || isBuff || isTyped) {
      if (isArray(objValue)) {
        newValue = objValue;
      } else if (isArrayLikeObject(objValue)) {
        newValue = copyArray(objValue);
      } else if (isBuff) {
        isCommon = false;
        newValue = cloneBuffer(srcValue);
      } else if (isTyped) {
        isCommon = false;
        newValue = cloneTypedArray(srcValue);
      } else {
        newValue = [];
      }
    } else if (isPlainObject(srcValue) || isArguments(srcValue)) {
      newValue = objValue;
      if (isArguments(objValue)) {
        newValue = toPlainObject(objValue);
      } else if (!isObject(objValue) || isFunction(objValue)) {
        newValue = initCloneObject(srcValue);
      }
    } else {
      isCommon = false;
    }
  }
  if (isCommon) {
    stack.set(srcValue, newValue);
    mergeFunc(newValue, srcValue, srcIndex, customizer, stack);
    stack["delete"](srcValue);
  }
  assignMergeValue(object, key, newValue);
}
function baseMerge(object, source, srcIndex, customizer, stack) {
  if (object === source) {
    return;
  }
  baseFor(source, function(srcValue, key) {
    stack || (stack = new Stack());
    if (isObject(srcValue)) {
      baseMergeDeep(object, source, key, srcIndex, baseMerge, customizer, stack);
    } else {
      var newValue = customizer ? customizer(safeGet(object, key), srcValue, key + "", object, source, stack) : void 0;
      if (newValue === void 0) {
        newValue = srcValue;
      }
      assignMergeValue(object, key, newValue);
    }
  }, keysIn);
}
var merge = createAssigner(function(object, source, srcIndex) {
  baseMerge(object, source, srcIndex);
});
const download$7 = {
  wait_download: "<0>Stahování začne během několika vteřin. Jestliže stahování nezačne automaticky,<1>klikněte zde</1>a stahování začne.</0>"
};
const form$7 = {
  required: "Toto pole je povinné",
  minLength: "Napište nejméně {{num}} znaků",
  maxLength: "Maximální počet povolených znaků {{num}}",
  pattern: "Tato hodnota není platná",
  min: "Minimální počet znaků {{num}}",
  max: "Maximální počet znaků {{num}}",
  add_file: "Přidat soubor",
  too_many_files: "Příliš mnoho souborů",
  too_few_files: "Příliš málo aouborů",
  file_too_big: "Vybraný soubor je příliš velký",
  invalid_file: "Vybraný soubor není platný",
  max_files: "Maximální počet souborů",
  min_files: "Minimální počet souborů",
  types_allowed: "Povolené soubory",
  max_weight: "Maximální velikost souborů",
  submit_file_is_uploading: "Musíte počkat pro odeslání, abyste mohl skončit před podáním formuláře",
  upload_error: "Došlo k chybě při odesílání vašeho souboru. Prosím, zkuste znovu.",
  submit_validation_error: "Došlo k chybě během podání vašeho souboru. Prosím, zkontrolujte data a zkuste znovu.",
  submit_error: "Došlo k chybě během podání vašeho souboru.. Jestliže problém trvá, kontaktujte nás.",
  privacy: "Četl jsem a souhlasím se <1><0>Zásadami soukromí</0></1>",
  terms: "Četl jsem a souhlasím se <1><0>smluvními podmínkami</1>.",
  login_prefill: "<0>Přihlaste se</0> pro automatické doplnění vašich informací do formuláře.",
  signup_suggestion: "<0>Přihlaste se nyní</0> pro doplnění vašich dat do formulářů!",
  captcha_not_resolved: "Musíte potvrdit, že jste člověk před odesláním formuláře.",
  invalid_phone_prefix: "Předčíslí není platné",
  invalid_email: "Neplatná email adresa",
  select: {
    create: "{userInput}",
    loading: "Nahrávání...",
    no_options: "Žádné možnosti"
  },
  invalid_phone: "Telefonní číslo je neplatné"
};
const pricing$7 = {
  recommended: "Doporučeno",
  discounted_to: "Podpora končí {{date}}",
  per_month: "měsíčně",
  per_year: "ročně",
  free: "ZDARMA",
  badge_new: "nový",
  price_not_available: "Cena není přechodně k dispozici",
  btn_buy_now: "Kupte nyní",
  badge_video: "video",
  incremental_version: "Vše, co bylo nabízeno v předchozích verzích a více:",
  prices_starting_from: "Ceny začínají na"
};
const yes$7 = "ano";
const no$7 = "ne";
const next$7 = "Další";
const back$7 = "Zpět";
const language$7 = "Jazyk";
const preferences$7 = "Předvolby";
const go_back$7 = "Krok zpět";
const open$7 = "Otevřít";
const close$7 = "Zavřít";
const login$7 = "Přihlásit";
const login_error$7 = "Něco se pokazilo. Prosím, zkuste znovu.";
const no_video_found$7 = "Video nenalezeno.";
const watch_playlist_on_youtube$7 = "Podívejte se na videa na YouTube";
const search_video$7 = "Hledat video";
const logout$7 = "Odhlásit";
const admin_panel$7 = "Panel systému";
const my_products$7 = "Mé výrobky";
const profile$7 = "Profil";
const starting_from$7 = "Začínající od";
const address$7 = "Adresa";
const more_info$7 = "Více informací";
const click_to_expand$7 = "Kliknout pro rozbalení";
const skip$7 = "Skok";
const signup$7 = "Přihlásit";
const loading$7 = "Nahrávání...";
const cookie_policy$7 = "Nastavení cookie";
const privacy_policy$7 = "Nastavení soukromí";
const warning$7 = "Varování";
const error$7 = "Chyba";
const no_results$7 = "Nebyly nalezeny žádné výsledky.";
const read_more$7 = "Read more";
const recaptcha$7 = "Chráněno ověřením";
const captcha$7 = {
  error_generic: "Nelze ověřit. Prosím, znovu načtěte stránku a zkuste to znovu.",
  error_threshold: "Zdá se, že nejste člověk. Počkejte prosím několik minut a zkuste to znovu."
};
const feed_not_available$7 = "Krmivo není k dispozici";
const no_elements_found$7 = "Nebyly nalezeny žádné prvky";
const previous_page$7 = "Předchozí";
const next_page$7 = "Další";
const docenti$7 = "docenti";
const yearly$7 = "Roční";
const monthly$7 = "Měsíční";
const share_on$7 = "Sdílet na";
const show_more$7 = "Zobrazit více...";
const show_less$7 = "Ukaž méně";
const csCore = {
  download: download$7,
  form: form$7,
  pricing: pricing$7,
  yes: yes$7,
  no: no$7,
  next: next$7,
  back: back$7,
  language: language$7,
  preferences: preferences$7,
  go_back: go_back$7,
  open: open$7,
  close: close$7,
  login: login$7,
  login_error: login_error$7,
  no_video_found: no_video_found$7,
  watch_playlist_on_youtube: watch_playlist_on_youtube$7,
  search_video: search_video$7,
  logout: logout$7,
  admin_panel: admin_panel$7,
  my_products: my_products$7,
  profile: profile$7,
  starting_from: starting_from$7,
  address: address$7,
  more_info: more_info$7,
  click_to_expand: click_to_expand$7,
  skip: skip$7,
  signup: signup$7,
  loading: loading$7,
  cookie_policy: cookie_policy$7,
  privacy_policy: privacy_policy$7,
  warning: warning$7,
  error: error$7,
  no_results: no_results$7,
  read_more: read_more$7,
  recaptcha: recaptcha$7,
  captcha: captcha$7,
  feed_not_available: feed_not_available$7,
  no_elements_found: no_elements_found$7,
  previous_page: previous_page$7,
  next_page: next_page$7,
  docenti: docenti$7,
  yearly: yearly$7,
  monthly: monthly$7,
  share_on: share_on$7,
  show_more: show_more$7,
  show_less: show_less$7
};
const en$7 = "English";
const it$7 = "Italiano";
const es$7 = "Español";
const fr$7 = "Français";
const de$7 = "Deutsch";
const pt$7 = "Português";
const pl$7 = "Polskie";
const ru$7 = "Русский";
const cs$7 = "Čeština";
const hu$7 = "Magyar";
const csLanguages = {
  en: en$7,
  "en-GB": "English",
  it: it$7,
  "it-IT": "Italiano",
  es: es$7,
  "es-ES": "Español",
  fr: fr$7,
  "fr-FR": "Français",
  de: de$7,
  "de-DE": "Deutsch",
  pt: pt$7,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$7,
  "pl-PL": "Polskie",
  ru: ru$7,
  "ru-RU": "Русский",
  cs: cs$7,
  "cs-CZ": "Čeština",
  hu: hu$7,
  "hu-HU": "Magyar"
};
const download$6 = {
  wait_download: "<0>Der Download sollte in wenigen Sekunden beginnen. Wenn es nicht automatisch startet, <1>klicken Sie hier</1> , um die Datei herunterzuladen.</0>"
};
const form$6 = {
  required: "Dieses Bereich wird benötigt",
  minLength: "Geben Sie mindestens {{num}} Zeichen ein",
  maxLength: "Es sind maximal {{num}} Zeichen erlaubt",
  pattern: "Der Wert ist ungültig",
  min: "Zulässiger Mindestwert: {{num}}",
  max: "Maximal zulässiger Wert: {{num}}",
  add_file: "Datei hinzufügen",
  too_many_files: "Zu viele Dateien",
  too_few_files: "Zu wenige Dateien",
  file_too_big: "Die ausgewählte Datei ist zu groß",
  invalid_file: "Die ausgewählte Datei ist ungültig",
  max_files: "Maximale Anzahl von Dateien",
  min_files: "Mindestanzahl von Dateien",
  types_allowed: "Dateien erlaubt",
  max_weight: "Maximale Dateigröße",
  submit_file_is_uploading: "Sie müssen warten, bis der Upload abgeschlossen ist, bevor Sie Ihr Formular absenden.",
  upload_error: "Beim Hochladen Ihrer Datei ist etwas schief gelaufen. Bitte versuche es erneut",
  submit_validation_error: "Beim Absenden Ihres Formulars ist etwas schief gelaufen. Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut.",
  submit_error: "Beim Absenden Ihres Formulars ist etwas schief gelaufen. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte.",
  privacy: "Ich habe die <1><0>Datenschutzerklärung</0></1> gelesen und stimme ihr zu",
  terms: "Ich habe die <1>Nutzungsbedingungen</1> gelesen und stimme ihnen zu.",
  login_prefill: "<0>Anmelden</0>, um Ihre Informationen automatisch in das Formular einzutragen.",
  signup_suggestion: "<0>Melden Sie sich jetzt an</0>, um Ihre Daten in unseren Formularen vorab auszufüllen!",
  captcha_not_resolved: "Sie müssen bestätigen, dass Sie ein Mensch sind, bevor Sie Ihr Formular absenden.",
  invalid_phone_prefix: "Präfix ungültig",
  invalid_email: "Email adresse nicht gültig",
  select: {
    create: "{userInput}",
    loading: "Wird geladen...",
    no_options: "Keine Optionen gefunden"
  },
  invalid_phone: "Die Telefonnummer ist ungültig"
};
const pricing$6 = {
  recommended: "Empfohlen",
  discounted_to: "Angebot endet am {{date}}",
  per_month: "Monat",
  per_year: "Jahr",
  free: "FREE",
  badge_new: "neu",
  price_not_available: "Preis vorübergehend nicht verfügbar",
  btn_buy_now: "Kaufe jetzt",
  badge_video: "Video",
  incremental_version: "Alles, was in der vorherigen Version angeboten wurde und mehr:",
  prices_starting_from: "Preise ab"
};
const yes$6 = "Ja";
const no$6 = "Nein";
const next$6 = "Nächste";
const back$6 = "Zurück";
const language$6 = "Sprache";
const preferences$6 = "Einstellungen";
const go_back$6 = "Geh zurück";
const open$6 = "Offen";
const close$6 = "schließen";
const login$6 = "Login";
const login_error$6 = "Etwas ist schief gelaufen. Bitte versuche es erneut.";
const no_video_found$6 = "Kein Video gefunden.";
const watch_playlist_on_youtube$6 = "Sehen Sie sich die gesamte Playlist auf YouTube an";
const search_video$6 = "Videos suchen";
const logout$6 = "Logout";
const admin_panel$6 = "Admin Paneele";
const my_products$6 = "Meine Produkte";
const profile$6 = "Profil";
const starting_from$6 = "Ab";
const address$6 = "Adresse";
const more_info$6 = "Mehr Informationen";
const click_to_expand$6 = "Zum erweitern klicken";
const skip$6 = "überspringen";
const signup$6 = "Anmelden";
const loading$6 = "Wird geladen...";
const cookie_policy$6 = "Cookie-Richtlinie";
const privacy_policy$6 = "Datenschutz-Bestimmungen";
const warning$6 = "Warnung";
const error$6 = "Fehler";
const no_results$6 = "Keine Ergebnisse gefunden.";
const read_more$6 = "Read more";
const recaptcha$6 = "Geschützt durch reCAPTCHA";
const captcha$6 = {
  error_generic: "Das Captcha konnte nicht überprüft werden. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
  error_threshold: "Es scheint, dass du kein Mensch bist. Bitte warten Sie ein paar Minuten und versuchen Sie es erneut."
};
const feed_not_available$6 = "Feed nicht verfügbar";
const no_elements_found$6 = "Keine Elemente gefunden";
const previous_page$6 = "Vorherige";
const next_page$6 = "Nächste";
const docenti$6 = "docenti";
const yearly$6 = "Jährlich";
const monthly$6 = "Monatlich";
const share_on$6 = "Teilen auf";
const show_more$6 = "Zeig mehr...";
const show_less$6 = "Zeige weniger";
const deCore = {
  download: download$6,
  form: form$6,
  pricing: pricing$6,
  yes: yes$6,
  no: no$6,
  next: next$6,
  back: back$6,
  language: language$6,
  preferences: preferences$6,
  go_back: go_back$6,
  open: open$6,
  close: close$6,
  login: login$6,
  login_error: login_error$6,
  no_video_found: no_video_found$6,
  watch_playlist_on_youtube: watch_playlist_on_youtube$6,
  search_video: search_video$6,
  logout: logout$6,
  admin_panel: admin_panel$6,
  my_products: my_products$6,
  profile: profile$6,
  starting_from: starting_from$6,
  address: address$6,
  more_info: more_info$6,
  click_to_expand: click_to_expand$6,
  skip: skip$6,
  signup: signup$6,
  loading: loading$6,
  cookie_policy: cookie_policy$6,
  privacy_policy: privacy_policy$6,
  warning: warning$6,
  error: error$6,
  no_results: no_results$6,
  read_more: read_more$6,
  recaptcha: recaptcha$6,
  captcha: captcha$6,
  feed_not_available: feed_not_available$6,
  no_elements_found: no_elements_found$6,
  previous_page: previous_page$6,
  next_page: next_page$6,
  docenti: docenti$6,
  yearly: yearly$6,
  monthly: monthly$6,
  share_on: share_on$6,
  show_more: show_more$6,
  show_less: show_less$6
};
const en$6 = "English";
const it$6 = "Italiano";
const es$6 = "Español";
const fr$6 = "Français";
const de$6 = "Deutsch";
const pt$6 = "Português";
const pl$6 = "Polskie";
const ru$6 = "Русский";
const cs$6 = "Čeština";
const hu$6 = "Magyar";
const deLanguages = {
  en: en$6,
  "en-GB": "English",
  it: it$6,
  "it-IT": "Italiano",
  es: es$6,
  "es-ES": "Español",
  fr: fr$6,
  "fr-FR": "Français",
  de: de$6,
  "de-DE": "Deutsch",
  pt: pt$6,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$6,
  "pl-PL": "Polskie",
  ru: ru$6,
  "ru-RU": "Русский",
  cs: cs$6,
  "cs-CZ": "Čeština",
  hu: hu$6,
  "hu-HU": "Magyar"
};
const download$5 = {
  wait_download: "<0>Download should starts in a few seconds. If it doesn't start automatically, <1>click here</1> to download the file.</0>"
};
const form$5 = {
  required: "This field is required",
  minLength: "Type at least {{num}} characters",
  maxLength: "Max {{num}} characters are allowed",
  pattern: "The value is not valid",
  min: "Minimum value allowed: {{num}}",
  max: "Maximum value allowed: {{num}}",
  add_file: "Add file",
  too_many_files: "Too many files",
  too_few_files: "Too few files",
  file_too_big: "The selected file is too big",
  invalid_file: "The selected file is not valid",
  max_files: "Max number of files",
  min_files: "Min number of files",
  types_allowed: "Files allowed",
  max_weight: "Max file size",
  submit_file_is_uploading: "You need to wait for the upload to finish before submitting your form.",
  upload_error: "Something went wrong while uploading your file. Please, try again.",
  submit_validation_error: "Something went wrong while submitting your form. Please, check your data and try again.",
  submit_error: "Something went wrong while submitting your form. If the problem persists, please contact us.",
  privacy: "I have read and agreed to the <1><0>Privacy Policy</0></1>",
  terms: "I have read and agreed to the <1>terms and conditions</1>.",
  login_prefill: "<0>Login</0> to automatically fill your information in the form.",
  signup_suggestion: "<0>Sign up now</0> to pre-fill your data in our forms!",
  captcha_not_resolved: "You have to confirm that you are a human before submitting your form.",
  invalid_phone_prefix: "Prefix not valid",
  invalid_email: "Email address not valid",
  select: {
    create: "{userInput}",
    loading: "Loading...",
    no_options: "No options found"
  },
  invalid_phone: "The phone number is invalid"
};
const pricing$5 = {
  recommended: "Recommended",
  discounted_to: "Promotion ends {{date}}",
  per_month: "month",
  per_year: "year",
  free: "FREE",
  badge_new: "new",
  price_not_available: "Price temporarily not available",
  btn_buy_now: "Buy now",
  badge_video: "video",
  incremental_version: "Everything offered in the previous version and more:",
  prices_starting_from: "Prices starting from"
};
const yes$5 = "yes";
const no$5 = "no";
const next$5 = "Next";
const back$5 = "Back";
const language$5 = "Language";
const preferences$5 = "Preferences";
const go_back$5 = "Go back";
const open$5 = "Open";
const close$5 = "Close";
const login$5 = "Login";
const login_error$5 = "Something went wrong. Please, try again.";
const no_video_found$5 = "No video found.";
const watch_playlist_on_youtube$5 = "Watch the entire playlist on YouTube";
const search_video$5 = "Search video";
const logout$5 = "Logout";
const admin_panel$5 = "Admin panel";
const my_products$5 = "My products";
const profile$5 = "Profile";
const starting_from$5 = "Starting from";
const address$5 = "Address";
const more_info$5 = "More information";
const click_to_expand$5 = "Click to expand";
const skip$5 = "Skip";
const signup$5 = "Signup";
const loading$5 = "Loading...";
const cookie_policy$5 = "Cookie policy";
const privacy_policy$5 = "Privacy policy";
const warning$5 = "Warning";
const error$5 = "Error";
const no_results$5 = "No results found.";
const read_more$5 = "Read more";
const recaptcha$5 = "Protected by reCAPTCHA";
const captcha$5 = {
  error_generic: "Unable to verify the captcha. Please, reload the page and try again.",
  error_threshold: "It seems you're not a human. Please, wait a few minutes and try again."
};
const feed_not_available$5 = "Feed not available";
const no_elements_found$5 = "No elements found";
const previous_page$5 = "Previous";
const next_page$5 = "Next";
const docenti$5 = "docenti";
const yearly$5 = "Yearly";
const monthly$5 = "Monthly";
const share_on$5 = "Share on";
const show_more$5 = "Show more...";
const show_less$5 = "Show less";
const generic_error$1 = "An error occurred while loading this area.";
const enCore = {
  download: download$5,
  form: form$5,
  pricing: pricing$5,
  yes: yes$5,
  no: no$5,
  next: next$5,
  back: back$5,
  language: language$5,
  preferences: preferences$5,
  go_back: go_back$5,
  open: open$5,
  close: close$5,
  login: login$5,
  login_error: login_error$5,
  no_video_found: no_video_found$5,
  watch_playlist_on_youtube: watch_playlist_on_youtube$5,
  search_video: search_video$5,
  logout: logout$5,
  admin_panel: admin_panel$5,
  my_products: my_products$5,
  profile: profile$5,
  starting_from: starting_from$5,
  address: address$5,
  more_info: more_info$5,
  click_to_expand: click_to_expand$5,
  skip: skip$5,
  signup: signup$5,
  loading: loading$5,
  cookie_policy: cookie_policy$5,
  privacy_policy: privacy_policy$5,
  warning: warning$5,
  error: error$5,
  no_results: no_results$5,
  read_more: read_more$5,
  recaptcha: recaptcha$5,
  captcha: captcha$5,
  feed_not_available: feed_not_available$5,
  no_elements_found: no_elements_found$5,
  previous_page: previous_page$5,
  next_page: next_page$5,
  docenti: docenti$5,
  yearly: yearly$5,
  monthly: monthly$5,
  share_on: share_on$5,
  show_more: show_more$5,
  show_less: show_less$5,
  generic_error: generic_error$1
};
const en$5 = "English";
const it$5 = "Italiano";
const es$5 = "Español";
const fr$5 = "Français";
const de$5 = "Deutsch";
const pt$5 = "Português";
const pl$5 = "Polskie";
const ru$5 = "Русский";
const cs$5 = "Čeština";
const hu$5 = "Magyar";
const enLanguages = {
  en: en$5,
  "en-GB": "English",
  it: it$5,
  "it-IT": "Italiano",
  es: es$5,
  "es-ES": "Español",
  fr: fr$5,
  "fr-FR": "Français",
  de: de$5,
  "de-DE": "Deutsch",
  pt: pt$5,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$5,
  "pl-PL": "Polskie",
  ru: ru$5,
  "ru-RU": "Русский",
  cs: cs$5,
  "cs-CZ": "Čeština",
  hu: hu$5,
  "hu-HU": "Magyar"
};
const download$4 = {
  wait_download: "<0>La descarga debería comenzar en unos segundos. Si no se inicia automáticamente, <1>haga click aquí</1> para descargar el archivo.</0>"
};
const form$4 = {
  required: "Este campo es obligatorio",
  minLength: "Escriba al menos {{num}} caracteres",
  maxLength: "Se permite un máximo de {{num}} caracteres",
  pattern: "El valor no es válido",
  min: "Valor mínimo permitido: {{num}}",
  max: "Valor máximo permitido: {{num}}",
  add_file: "Añadir fichero",
  too_many_files: "Demasiados archivos",
  too_few_files: "Demasiado pocos archivos",
  file_too_big: "La selección de archivos es demasiado grande",
  invalid_file: "La selección de archivos no es válida",
  max_files: "Número máximo de archivos",
  min_files: "Número mínimo de archivos",
  types_allowed: "Archivos permitidos",
  max_weight: "Tamaño máximo de archivo",
  submit_file_is_uploading: "Debe esperar a que la carga finalice antes de enviar el formulario.",
  upload_error: "Algo ha ido mal durante la carga del archivo. Por favor, inténtelo de nuevo.",
  submit_validation_error: "Algo ha ido mal durante el envío del formulario. Por favor, compruebe sus datos y pruebe de nuevo.",
  submit_error: "Algo ha ido mal durante el envío del formulario. Si el problema persiste, por favor, póngase en contacto con nosotros.",
  privacy: "He leído y aceptado la <1><0>Política de Privacidad</0></1>",
  terms: "He leído y aceptado los <1><0>términos y condiciones<</0></1> ",
  login_prefill: "<0>Inicie sesión</0> para rellenar automáticamente sus datos en el formulario.",
  signup_suggestion: "<0>¡Regístrese ahora</0> para rellenar previamente sus datos en nuestros formularios!",
  captcha_not_resolved: "Tienes que confirmar que eres una persona antes de enviar tu formulario.",
  invalid_phone_prefix: "Prefijo no válido",
  invalid_email: "Dirección de correo electrónico no válida",
  select: {
    create: "{userInput}",
    loading: "Cargando ...",
    no_options: "No se han encuentrado opciones"
  },
  invalid_phone: "El número telefónico es inválido"
};
const pricing$4 = {
  recommended: "Recomendado",
  discounted_to: "La promoción finaliza el {{date}}",
  per_month: "mes",
  per_year: "año",
  free: "GRATIS",
  badge_new: "nuevo",
  price_not_available: "Precio no disponible temporalmente",
  btn_buy_now: "Comprar ahora",
  badge_video: "vídeo",
  incremental_version: "Todo lo ofrecido en la versión anterior y más:",
  prices_starting_from: "Los precios comienzan en"
};
const yes$4 = "si";
const no$4 = "no";
const next$4 = "Siguiente";
const back$4 = "Anterior";
const language$4 = "Lenguaje";
const preferences$4 = "Preferencias";
const go_back$4 = "Volver atrás";
const open$4 = "Abrir";
const close$4 = "Cerrar";
const login$4 = "Iniciar sesión";
const login_error$4 = "Algo fue mal. Por favor, inténtelo de nuevo.";
const no_video_found$4 = "No se ha encontrado ningún vídeo.";
const watch_playlist_on_youtube$4 = "Vea la lista de vídeos entera en YouTube";
const search_video$4 = "Burcar vídeo";
const logout$4 = "Salir";
const admin_panel$4 = "Panel de administración";
const my_products$4 = "Mis productos";
const profile$4 = "Perfil";
const starting_from$4 = "Empezar desde";
const address$4 = "Dirección";
const more_info$4 = "Más información";
const click_to_expand$4 = "Click para expandir";
const skip$4 = "Omitir";
const signup$4 = "Registrarse";
const loading$4 = "Cargando";
const cookie_policy$4 = "Politica de Cookies";
const privacy_policy$4 = "Política de privacidad";
const warning$4 = "Advertencia";
const error$4 = "Error";
const no_results$4 = "No se han encontrado resultados.";
const read_more$4 = "Leer más";
const recaptcha$4 = "Protegida por reCAPTCHA";
const captcha$4 = {
  error_generic: "No se puede verificar el captcha. Por favor, recarga la página y vuelve a intentarlo.",
  error_threshold: "Parece que no eres un humano. Por favor, espere unos minutos e intente de nuevo."
};
const feed_not_available$4 = "Feed no disponible";
const no_elements_found$4 = "No se encontraron elementos";
const previous_page$4 = "Previa";
const next_page$4 = "Próxima";
const docenti$4 = "docenti";
const yearly$4 = "Anual";
const monthly$4 = "Mensual";
const share_on$4 = "Compartir en";
const show_more$4 = "Mostrar más...";
const show_less$4 = "Muestra menos";
const esCore = {
  download: download$4,
  form: form$4,
  pricing: pricing$4,
  yes: yes$4,
  no: no$4,
  next: next$4,
  back: back$4,
  language: language$4,
  preferences: preferences$4,
  go_back: go_back$4,
  open: open$4,
  close: close$4,
  login: login$4,
  login_error: login_error$4,
  no_video_found: no_video_found$4,
  watch_playlist_on_youtube: watch_playlist_on_youtube$4,
  search_video: search_video$4,
  logout: logout$4,
  admin_panel: admin_panel$4,
  my_products: my_products$4,
  profile: profile$4,
  starting_from: starting_from$4,
  address: address$4,
  more_info: more_info$4,
  click_to_expand: click_to_expand$4,
  skip: skip$4,
  signup: signup$4,
  loading: loading$4,
  cookie_policy: cookie_policy$4,
  privacy_policy: privacy_policy$4,
  warning: warning$4,
  error: error$4,
  no_results: no_results$4,
  read_more: read_more$4,
  recaptcha: recaptcha$4,
  captcha: captcha$4,
  feed_not_available: feed_not_available$4,
  no_elements_found: no_elements_found$4,
  previous_page: previous_page$4,
  next_page: next_page$4,
  docenti: docenti$4,
  yearly: yearly$4,
  monthly: monthly$4,
  share_on: share_on$4,
  show_more: show_more$4,
  show_less: show_less$4
};
const en$4 = "English";
const it$4 = "Italiano";
const es$4 = "Español";
const fr$4 = "Français";
const de$4 = "Deutsch";
const pt$4 = "Português";
const pl$4 = "Polskie";
const ru$4 = "Русский";
const cs$4 = "Čeština";
const hu$4 = "Magyar";
const esLanguages = {
  en: en$4,
  "en-GB": "English",
  it: it$4,
  "it-IT": "Italiano",
  es: es$4,
  "es-ES": "Español",
  fr: fr$4,
  "fr-FR": "Français",
  de: de$4,
  "de-DE": "Deutsch",
  pt: pt$4,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$4,
  "pl-PL": "Polskie",
  ru: ru$4,
  "ru-RU": "Русский",
  cs: cs$4,
  "cs-CZ": "Čeština",
  hu: hu$4,
  "hu-HU": "Magyar"
};
const download$3 = {
  wait_download: "<0> Le téléchargement devrait commencer dans quelques secondes. S'il ne démarre pas automatiquement, <1> cliquez ici </1> pour télécharger le fichier. </0>"
};
const form$3 = {
  required: "Ce champ est requis",
  minLength: "Saisissez au moins {{num}} caractères",
  maxLength: "Max {{num}} caractères sont autorisés",
  pattern: "La valeur n'est pas valide",
  min: "Valeur minimale autorisée: {{num}}",
  max: "Valeur maximale autorisée: {{num}}",
  add_file: "Ajouter le fichier",
  too_many_files: "Trop de fichiers",
  too_few_files: "Trop peu de fichiers",
  file_too_big: "Le fichier sélectionné est trop grande",
  invalid_file: "Le fichier sélectionné n'est pas valide",
  max_files: "Nombre maximum de fichiers",
  min_files: "Nombre minimum de fichiers",
  types_allowed: "Fichiers autorisés",
  max_weight: "Taille maximale du fichier",
  submit_file_is_uploading: "Vous devez attendre la fin du téléchargement avant de soumettre votre formulaire.",
  upload_error: "Une erreur s'est produite lors du téléchargement de votre fichier. Veuillez réessayer.",
  submit_validation_error: "Une erreur s'est produite lors de la soumission de votre formulaire. Veuillez vérifier vos données et réessayer.",
  submit_error: "Une erreur s'est produite lors de la soumission de votre formulaire. Si le problème persiste, veuillez nous contacter.",
  privacy: "J'ai lu et j'accepte la <1><0>Politique de confidentialité</0></1>",
  terms: "J'ai lu et j'accepte les <1>termes et conditions</1>.",
  login_prefill: "<0>Connectez-vous</0> pour renseigner automatiquement vos informations dans le formulaire.",
  signup_suggestion: "<0>Inscrivez-vous dès maintenant</0> pour pré-remplir vos données dans nos formulaires!",
  captcha_not_resolved: "Vous devez confirmer que vous êtes un être humain avant de soumettre votre formulaire.",
  invalid_phone_prefix: "Préfixe non valide",
  invalid_email: "Adresse email non valide",
  select: {
    create: "{userInput}",
    loading: "Chargement...",
    no_options: "Aucune option trouvée"
  },
  invalid_phone: "Le numéro de téléphone est invalide"
};
const pricing$3 = {
  recommended: "conseillé",
  discounted_to: "La promotion se termine le {{date}}",
  per_month: "Mois",
  per_year: "Année",
  free: "FREE",
  badge_new: "new",
  price_not_available: "Prix ​​temporairement indisponible",
  btn_buy_now: "Acheter maintenant",
  badge_video: "video",
  incremental_version: "Tout ce qui est offert dans la version précédente et plus :",
  prices_starting_from: "Tarifs à partir de"
};
const yes$3 = "Oui";
const no$3 = "No";
const next$3 = "Suivant";
const back$3 = "Arrière";
const language$3 = "Langue";
const preferences$3 = "Préférences";
const go_back$3 = "Retourner";
const open$3 = "Ouvrir";
const close$3 = "Fermer";
const login$3 = "Login";
const login_error$3 = "Quelque chose s'est mal passé. Veuillez réessayer.";
const no_video_found$3 = "Aucune vidéo trouvée.";
const watch_playlist_on_youtube$3 = "Regardez toute la playlist sur YouTube";
const search_video$3 = "Rechercher une vidéo";
const logout$3 = "Logout";
const admin_panel$3 = "Admin panneau";
const my_products$3 = "Mes produits";
const profile$3 = "Profil";
const starting_from$3 = "Commençant par";
const address$3 = "Adresse";
const more_info$3 = "Plus d'informations";
const click_to_expand$3 = "Cliquez pour agrandir";
const skip$3 = "Sauter";
const signup$3 = "S'inscrire";
const loading$3 = "Chargement...";
const cookie_policy$3 = "Politique relative aux cookies";
const privacy_policy$3 = "Politique de confidentialité";
const warning$3 = "Avertissement";
const error$3 = "Erreur";
const no_results$3 = "Aucun résultat trouvé.";
const read_more$3 = "En savoir plus";
const recaptcha$3 = "Protégé par reCAPTCHA";
const captcha$3 = {
  error_generic: "Impossible de vérifier le captcha. Veuillez recharger la page et réessayer.",
  error_threshold: "Il semble que tu n'es pas un humain. S'il vous plait attendez quelques minutes et réessayez."
};
const feed_not_available$3 = "Flux non disponible";
const no_elements_found$3 = "Aucun élément trouvé";
const previous_page$3 = "Précédent";
const next_page$3 = "Suivant";
const docenti$3 = "docenti";
const yearly$3 = "Annuel";
const monthly$3 = "Mensuel";
const share_on$3 = "Partager sur";
const show_more$3 = "Montre plus...";
const show_less$3 = "Montrer moins";
const frCore = {
  download: download$3,
  form: form$3,
  pricing: pricing$3,
  yes: yes$3,
  no: no$3,
  next: next$3,
  back: back$3,
  language: language$3,
  preferences: preferences$3,
  go_back: go_back$3,
  open: open$3,
  close: close$3,
  login: login$3,
  login_error: login_error$3,
  no_video_found: no_video_found$3,
  watch_playlist_on_youtube: watch_playlist_on_youtube$3,
  search_video: search_video$3,
  logout: logout$3,
  admin_panel: admin_panel$3,
  my_products: my_products$3,
  profile: profile$3,
  starting_from: starting_from$3,
  address: address$3,
  more_info: more_info$3,
  click_to_expand: click_to_expand$3,
  skip: skip$3,
  signup: signup$3,
  loading: loading$3,
  cookie_policy: cookie_policy$3,
  privacy_policy: privacy_policy$3,
  warning: warning$3,
  error: error$3,
  no_results: no_results$3,
  read_more: read_more$3,
  recaptcha: recaptcha$3,
  captcha: captcha$3,
  feed_not_available: feed_not_available$3,
  no_elements_found: no_elements_found$3,
  previous_page: previous_page$3,
  next_page: next_page$3,
  docenti: docenti$3,
  yearly: yearly$3,
  monthly: monthly$3,
  share_on: share_on$3,
  show_more: show_more$3,
  show_less: show_less$3
};
const en$3 = "English";
const it$3 = "Italiano";
const es$3 = "Español";
const fr$3 = "Français";
const de$3 = "Deutsch";
const pt$3 = "Português";
const pl$3 = "Polskie";
const ru$3 = "Русский";
const cs$3 = "Čeština";
const hu$3 = "Magyar";
const frLanguages = {
  en: en$3,
  "en-GB": "English",
  it: it$3,
  "it-IT": "Italiano",
  es: es$3,
  "es-ES": "Español",
  fr: fr$3,
  "fr-FR": "Français",
  de: de$3,
  "de-DE": "Deutsch",
  pt: pt$3,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$3,
  "pl-PL": "Polskie",
  ru: ru$3,
  "ru-RU": "Русский",
  cs: cs$3,
  "cs-CZ": "Čeština",
  hu: hu$3,
  "hu-HU": "Magyar"
};
const download$2 = {
  wait_download: "<0>A letöltés másodperceken belül elindul. Ha mégsem indul el, <1>kattints ide</1> a fájl letöltéséhez.</0>"
};
const form$2 = {
  required: "Ez a mező kötelező",
  minLength: "Írj be legalább {{num}} karaktert",
  maxLength: "A maximális karakterhossz {{num}}",
  pattern: "Érvénytelen érték",
  min: "Minimális érték: {{num}}",
  max: "Maximális érték: {{num}}",
  add_file: "Fájl hozzáadása",
  too_many_files: "Túl sok fájl",
  too_few_files: "Túl kevés fájl",
  file_too_big: "A kiválasztott fájl túl nagy",
  invalid_file: "A kiválasztott fájl érvénytelen",
  max_files: "Fájlok maximális száma",
  min_files: "Fájlok minimális száma",
  types_allowed: "Engedélyezett fájlok",
  max_weight: "Maximális fájl méret",
  submit_file_is_uploading: "Meg kell várnod a fájl feltöltését ahhoz, hogy elmenthesd az űrlapot.",
  upload_error: "Valamilyen hiba történt a fájl feltöltése során. Kérlek próbáld meg később.",
  submit_validation_error: "Valamilyen hiba történt az űrlap mentése közben. Kérlek ellenőrizd az adatokat és próbáld meg később.",
  submit_error: "Valamilyen hiba történt az űrlap mentése közben. Ha a probléma állandósul, kérlek lépj velünk kapcsolatba.",
  privacy: "Elolvastam és egyetértek az <1><0>adatvédelmi irányelvekkel</0></1>",
  terms: "Elolvastam és elfogadom az <1>általános szerződési feltételeket</1>",
  login_prefill: "<0>Lépj be</0>, hogy automatikusan kitöltődjön az űrlap a saját adataiddal.",
  signup_suggestion: "<0>Regisztrálj be</0>, hogy előre kitöltsük az adataidat az űr",
  captcha_not_resolved: "Meg kell bizonyosodnunk róla az űrlap elküldése előtt, hogy valódi ember vagy-e",
  invalid_phone_prefix: "Érvénytelen előtag",
  invalid_email: "Érvénytelen e-mail cím",
  select: {
    create: "{userInput}",
    loading: "Betöltés...",
    no_options: "Nem található opciók"
  },
  invalid_phone: "A telefonszám érvénytelen"
};
const pricing$2 = {
  recommended: "Ajánlott",
  discounted_to: "Promóció vége {{date}}",
  per_month: "hónap",
  per_year: "év",
  free: "INGYENES",
  badge_new: "új",
  price_not_available: "Az ár ideiglenesen nem elérhető",
  btn_buy_now: "Vedd meg most!",
  badge_video: "videó",
  incremental_version: "Minden, amit az előző verzió tartalmaz, plusz a következők:",
  prices_starting_from: "Árak -tól"
};
const yes$2 = "igen";
const no$2 = "nem";
const next$2 = "Következő";
const back$2 = "Vissza";
const language$2 = "Nyelv";
const preferences$2 = "Preferenciák";
const go_back$2 = "Vissza";
const open$2 = "Megnyitás";
const close$2 = "Bezárás";
const login$2 = "Bejelentkezés";
const login_error$2 = "Valamilye probléma történt. Kérlek próbáld újra később!";
const no_video_found$2 = "Nem található videó.";
const watch_playlist_on_youtube$2 = "Nézd meg a teljes lejátszási listát a YouTube-on";
const search_video$2 = "Videó keresése";
const logout$2 = "Kijelentkezés";
const admin_panel$2 = "Admin panel";
const my_products$2 = "Saját termékeim";
const profile$2 = "Profil";
const starting_from$2 = "Kezdve";
const address$2 = "Cím";
const more_info$2 = "További információk";
const click_to_expand$2 = "Kattints a lenyitáshoz";
const skip$2 = "Kihagyás";
const signup$2 = "Regisztráció";
const loading$2 = "Betöltés...";
const cookie_policy$2 = "Cookie szabályzat";
const privacy_policy$2 = "Adatvédelmi irányelvek";
const warning$2 = "Figyelem";
const error$2 = "Hiba";
const no_results$2 = "Nincs találat.";
const read_more$2 = "Read more";
const recaptcha$2 = "ReCAPTCHA védi";
const captcha$2 = {
  error_generic: "A captcha nem ellenőrizhető. Kérjük, töltse be újra az oldalt, és próbálja újra.",
  error_threshold: "Úgy tűnik, nem vagy ember. Kérjük, várjon néhány percet, és próbálja újra."
};
const feed_not_available$2 = "A hírcsatorna nem elérhető";
const no_elements_found$2 = "Nem található elemek";
const previous_page$2 = "Előző";
const next_page$2 = "Következő";
const docenti$2 = "docenti";
const yearly$2 = "Évi";
const monthly$2 = "Havi";
const share_on$2 = "Oszd meg";
const show_more$2 = "Mutass többet...";
const show_less$2 = "Mutass kevesebbet";
const huCore = {
  download: download$2,
  form: form$2,
  pricing: pricing$2,
  yes: yes$2,
  no: no$2,
  next: next$2,
  back: back$2,
  language: language$2,
  preferences: preferences$2,
  go_back: go_back$2,
  open: open$2,
  close: close$2,
  login: login$2,
  login_error: login_error$2,
  no_video_found: no_video_found$2,
  watch_playlist_on_youtube: watch_playlist_on_youtube$2,
  search_video: search_video$2,
  logout: logout$2,
  admin_panel: admin_panel$2,
  my_products: my_products$2,
  profile: profile$2,
  starting_from: starting_from$2,
  address: address$2,
  more_info: more_info$2,
  click_to_expand: click_to_expand$2,
  skip: skip$2,
  signup: signup$2,
  loading: loading$2,
  cookie_policy: cookie_policy$2,
  privacy_policy: privacy_policy$2,
  warning: warning$2,
  error: error$2,
  no_results: no_results$2,
  read_more: read_more$2,
  recaptcha: recaptcha$2,
  captcha: captcha$2,
  feed_not_available: feed_not_available$2,
  no_elements_found: no_elements_found$2,
  previous_page: previous_page$2,
  next_page: next_page$2,
  docenti: docenti$2,
  yearly: yearly$2,
  monthly: monthly$2,
  share_on: share_on$2,
  show_more: show_more$2,
  show_less: show_less$2
};
const en$2 = "English";
const it$2 = "Italiano";
const es$2 = "Español";
const fr$2 = "Français";
const de$2 = "Deutsch";
const pt$2 = "Português";
const pl$2 = "Polskie";
const ru$2 = "Русский";
const cs$2 = "Čeština";
const hu$2 = "Magyar";
const huLanguages = {
  en: en$2,
  "en-GB": "English",
  it: it$2,
  "it-IT": "Italiano",
  es: es$2,
  "es-ES": "Español",
  fr: fr$2,
  "fr-FR": "Français",
  de: de$2,
  "de-DE": "Deutsch",
  pt: pt$2,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$2,
  "pl-PL": "Polskie",
  ru: ru$2,
  "ru-RU": "Русский",
  cs: cs$2,
  "cs-CZ": "Čeština",
  hu: hu$2,
  "hu-HU": "Magyar"
};
const download$1 = {
  wait_download: "<0>Il download dovrebbe partire a momenti. Se non partisse automaticamente, <1>clicca qui</1> per scaricare il file.</0>"
};
const form$1 = {
  required: "Questo campo è obbligatorio",
  minLength: "Inserisci almeno {{num}} caratteri",
  maxLength: "Inserisci al massimo {{num}} caratteri",
  pattern: "Il valore inserito non è valido",
  min: "Inserisci un valore superiore a {{num}}",
  max: "Inserisci un valore inferiore a {{num}}",
  add_file: "Aggiungi file",
  too_many_files: "Hai selezionato troppi file",
  too_few_files: "Hai selezionato troppi pochi file",
  file_too_big: "Il file selezionato è troppo grande",
  invalid_file: "Il file selezionato non è valido",
  max_files: "Numero massimo di file",
  min_files: "Numero minimo di file",
  types_allowed: "File permessi",
  max_weight: "Dimensione massima file",
  submit_file_is_uploading: "Devi attendere che l'upload dei file sia terminato prima di procedere all'invio della form",
  upload_error: "Si è verificato un errore durante l'upload del file. Ti preghiamo di riprovare.",
  submit_validation_error: "Si è verificato un errore durante l'invio della form. Ti preghiamo di controllare i dati inseriti e riprovare.",
  submit_error: "Si è verificato un errore durante l'invio della form. Ti preghiamo di contattarci se il problema persiste.",
  privacy: "Ho letto e accettato la <1><0>Privacy Policy</0></1>",
  terms: "Ho letto e accettato i <1>termini e condizioni</1>.",
  login_prefill: "<0>Effettua l'accesso</0> per riempire automaticamente i campi con i tuoi dati.",
  signup_suggestion: "<0>Registrati ora</0> per compilare automaticamente i campi delle nostre form!",
  captcha_not_resolved: "Per procedere all'invio della form, è necessario verificare di essere umano.",
  invalid_phone_prefix: "Prefisso non valido",
  invalid_email: "Indirizzo email non valido",
  select: {
    create: "{userInput}",
    loading: "Caricamento...",
    no_options: "Nessun opzione trovata"
  },
  invalid_phone: "Il numero di telefono non è valido"
};
const pricing$1 = {
  recommended: "Consigliato",
  discounted_to: "Offerta valida fino al {{date}}",
  per_month: "mese",
  per_year: "anno",
  free: "GRATIS",
  badge_new: "new",
  price_not_available: "Prezzo momentaneamente non disponibile",
  btn_buy_now: "Acquista",
  badge_video: "video",
  incremental_version: "Tutto quello offerto nella versione precedente e in più:",
  prices_starting_from: "Prezzi a partire da"
};
const yes$1 = "si";
const no$1 = "no";
const next$1 = "Avanti";
const back$1 = "Indietro";
const language$1 = "Lingua";
const preferences$1 = "Preferenze";
const go_back$1 = "Torna indietro";
const open$1 = "Apri";
const close$1 = "Chiudi";
const login$1 = "Accedi";
const login_error$1 = "Si è verificato un errore. Ti preghiamo di riprovare.";
const no_video_found$1 = "Nessun video trovato.";
const watch_playlist_on_youtube$1 = "Guarda tutti i video su YouTube";
const search_video$1 = "Ricerca video";
const logout$1 = "Esci";
const admin_panel$1 = "Pannello admin";
const my_products$1 = "I miei prodotti";
const profile$1 = "Profilo";
const starting_from$1 = "A partire da";
const address$1 = "Indirizzo";
const more_info$1 = "Maggiori informazioni";
const click_to_expand$1 = "Clicca per espandere";
const skip$1 = "Salta";
const signup$1 = "Registrati";
const loading$1 = "Caricamento...";
const cookie_policy$1 = "Cookie policy";
const privacy_policy$1 = "Privacy policy";
const warning$1 = "Attenzione";
const error$1 = "Errore";
const no_results$1 = "Nessun risultato trovato.";
const read_more$1 = "Leggi di più";
const recaptcha$1 = "Protetto da reCAPTCHA";
const captcha$1 = {
  error_generic: "Impossibile verificare il captcha. Ti preghiamo di ricaricare la pagina e riprovare.",
  error_threshold: "Sembrerebbe che tu non sia umano. Ti preghiamo di attendere qualche minuto e riprovare."
};
const feed_not_available$1 = "Nessuna news disponibile";
const no_elements_found$1 = "Nessun elemento trovato";
const previous_page$1 = "Precedente";
const next_page$1 = "Successivo";
const docenti$1 = "Docenti";
const yearly$1 = "Annuale";
const monthly$1 = "Mensile";
const share_on$1 = "Condividi con";
const show_more$1 = "Mostra di più...";
const show_less$1 = "Mostra meno";
const generic_error = "Si è verificato un errore durante il caricamento di quest'area.";
const itCore = {
  download: download$1,
  form: form$1,
  pricing: pricing$1,
  yes: yes$1,
  no: no$1,
  next: next$1,
  back: back$1,
  language: language$1,
  preferences: preferences$1,
  go_back: go_back$1,
  open: open$1,
  close: close$1,
  login: login$1,
  login_error: login_error$1,
  no_video_found: no_video_found$1,
  watch_playlist_on_youtube: watch_playlist_on_youtube$1,
  search_video: search_video$1,
  logout: logout$1,
  admin_panel: admin_panel$1,
  my_products: my_products$1,
  profile: profile$1,
  starting_from: starting_from$1,
  address: address$1,
  more_info: more_info$1,
  click_to_expand: click_to_expand$1,
  skip: skip$1,
  signup: signup$1,
  loading: loading$1,
  cookie_policy: cookie_policy$1,
  privacy_policy: privacy_policy$1,
  warning: warning$1,
  error: error$1,
  no_results: no_results$1,
  read_more: read_more$1,
  recaptcha: recaptcha$1,
  captcha: captcha$1,
  feed_not_available: feed_not_available$1,
  no_elements_found: no_elements_found$1,
  previous_page: previous_page$1,
  next_page: next_page$1,
  docenti: docenti$1,
  yearly: yearly$1,
  monthly: monthly$1,
  share_on: share_on$1,
  show_more: show_more$1,
  show_less: show_less$1,
  generic_error
};
const en$1 = "English";
const it$1 = "Italiano";
const es$1 = "Español";
const fr$1 = "Français";
const de$1 = "Deutsch";
const pt$1 = "Português";
const pl$1 = "Polskie";
const ru$1 = "Русский";
const cs$1 = "Čeština";
const hu$1 = "Magyar";
const itLanguages = {
  en: en$1,
  "en-GB": "English",
  it: it$1,
  "it-IT": "Italiano",
  es: es$1,
  "es-ES": "Español",
  fr: fr$1,
  "fr-FR": "Français",
  de: de$1,
  "de-DE": "Deutsch",
  pt: pt$1,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl: pl$1,
  "pl-PL": "Polskie",
  ru: ru$1,
  "ru-RU": "Русский",
  cs: cs$1,
  "cs-CZ": "Čeština",
  hu: hu$1,
  "hu-HU": "Magyar"
};
const download = {
  wait_download: "<0>O download deve começar em alguns segundos. Se não iniciar automaticamente, <1>clique aqui</1> para baixar o arquivo.</0>"
};
const form = {
  required: "Este campo é obrigatório",
  minLength: "Digite pelo menos {{num}} caracteres",
  maxLength: "Máximo de {{num}} caracteres são permitidos",
  pattern: "O valor não é válido",
  min: "Valor mínimo permitido: {{num}}",
  max: "Valor máximo permitido: {{num}}",
  add_file: "Adicionar ficheiro",
  too_many_files: "Muitos arquivos",
  too_few_files: "Poucos arquivos",
  file_too_big: "O arquivo selecionado é muito grande",
  invalid_file: "O arquivo selecionado não é válido",
  max_files: "Número máximo de arquivos",
  min_files: "Número mínimo de arquivos",
  types_allowed: "Arquivos permitidos",
  max_weight: "Tamanho máximo do arquivo",
  submit_file_is_uploading: "Precisa aguardar a conclusão do upload antes de enviar o seu formulário.",
  upload_error: "Algo deu errado ao fazer o upload do seu arquivo. Por favor, tente novamente.",
  submit_validation_error: "Algo deu errado ao enviar seu formulário. Por favor, verifique os seus dados e tente novamente.",
  submit_error: "Algo deu errado ao enviar o seu formulário. Se o problema persistir, entre em contato conosco.",
  privacy: "Li e concordei com a <1><0>Política de Privacidade</0></1>",
  terms: "Li e concordei com os <1>termos e condições</1>",
  login_prefill: "<0>Faça login</0> para preencher automaticamente as suas informações no formulário.",
  signup_suggestion: "<0>Inscreva-se agora</0> para preencher previamente os seus dados em nossos formulários!",
  captcha_not_resolved: "Você precisa confirmar que é humano antes de enviar seu formulário.",
  invalid_phone_prefix: "Prefixo inválido",
  invalid_email: "Endereço de email inválido",
  select: {
    create: "{userInput}",
    loading: "Carregando...",
    no_options: "Nenhuma opção encontrada"
  },
  invalid_phone: "O número de telefone é inválido"
};
const pricing = {
  recommended: "Recomendado",
  discounted_to: "A promoção termina em {{date}}",
  per_month: "Mês",
  per_year: "Ano",
  free: "GRÁTIS",
  badge_new: "novo",
  price_not_available: "Preço temporariamente indisponível",
  btn_buy_now: "Compre agora",
  badge_video: "video",
  incremental_version: "Tudo oferecido na versão anterior e mais:",
  prices_starting_from: "Preços a partir de"
};
const yes = "sim";
const no = "não";
const next = "Próximo\n";
const back = "Voltar";
const language = "Idioma";
const preferences = "Preferências";
const go_back = "Volte atrás";
const open = "Abrir";
const close = "Fechar";
const login = "Registrar";
const login_error = "Algo deu errado. Por favor, tente novamente.";
const no_video_found = "Nenhum vídeo encontrado.";
const watch_playlist_on_youtube = "Veja a playlist inteira no YouTube";
const search_video = "Procurar vídeo";
const logout = "Sair";
const admin_panel = "Painel de administração";
const my_products = "Os meus produtos";
const profile = "Perfil";
const starting_from = "A partir de";
const address = "Endereço";
const more_info = "Mais informações";
const click_to_expand = "Clique para expandir";
const skip = "Pular";
const signup = "Inscrever-se";
const loading = "Carregando...";
const cookie_policy = "Política de cookies";
const privacy_policy = "Política de Privacidade";
const warning = "Aviso";
const error = "Erro";
const no_results = "Nenhum resultado encontrado.";
const read_more = "Consulte Mais informação";
const recaptcha = "Protegido por reCAPTCHA";
const captcha = {
  error_generic: "Não foi possível verificar o captcha. Por favor, recarregue a página e tente denovo.",
  error_threshold: "Parece que você não é humano. Por favor, aguarde alguns minutos e tente novamente."
};
const feed_not_available = "Feed não disponível";
const no_elements_found = "Nenhum elemento encontrado";
const previous_page = "Anterior";
const next_page = "Próximo";
const docenti = "docenti";
const yearly = "Anual";
const monthly = "Por mês";
const share_on = "Compartilhar no";
const show_more = "Mostre mais...";
const show_less = "Mostre menos";
const ptCore = {
  download,
  form,
  pricing,
  yes,
  no,
  next,
  back,
  language,
  preferences,
  go_back,
  open,
  close,
  login,
  login_error,
  no_video_found,
  watch_playlist_on_youtube,
  search_video,
  logout,
  admin_panel,
  my_products,
  profile,
  starting_from,
  address,
  more_info,
  click_to_expand,
  skip,
  signup,
  loading,
  cookie_policy,
  privacy_policy,
  warning,
  error,
  no_results,
  read_more,
  recaptcha,
  captcha,
  feed_not_available,
  no_elements_found,
  previous_page,
  next_page,
  docenti,
  yearly,
  monthly,
  share_on,
  show_more,
  show_less
};
const en = "English";
const it = "Italiano";
const es = "Español";
const fr = "Français";
const de = "Deutsch";
const pt = "Português";
const pl = "Polskie";
const ru = "Русский";
const cs = "Čeština";
const hu = "Magyar";
const ptLanguages = {
  en,
  "en-GB": "English",
  it,
  "it-IT": "Italiano",
  es,
  "es-ES": "Español",
  fr,
  "fr-FR": "Français",
  de,
  "de-DE": "Deutsch",
  pt,
  "pt-PT": "Português",
  "pt-BR": "Português",
  pl,
  "pl-PL": "Polskie",
  ru,
  "ru-RU": "Русский",
  cs,
  "cs-CZ": "Čeština",
  hu,
  "hu-HU": "Magyar"
};
const DEFAULT_RESOURCES = {
  cs: {
    core: csCore,
    languages: csLanguages
  },
  de: {
    core: deCore,
    languages: deLanguages
  },
  en: {
    core: enCore,
    languages: enLanguages
  },
  es: {
    core: esCore,
    languages: esLanguages
  },
  fr: {
    core: frCore,
    languages: frLanguages
  },
  hu: {
    core: huCore,
    languages: huLanguages
  },
  it: {
    core: itCore,
    languages: itLanguages
  },
  pt: {
    core: ptCore,
    languages: ptLanguages
  }
};
const { ContextProvider, useStore } = createState(
  "I18n",
  (set) => ({
    resources: DEFAULT_RESOURCES,
    registerResources: (resources) => {
      set((state) => {
        return {
          ...state,
          resources: merge(state.resources, resources)
        };
      });
    },
    i18nInstance: null,
    setI18nInstance: (newI18n) => {
      set((state) => ({
        ...state,
        i18nInstance: newI18n
      }));
    }
  }),
  (state, props) => ({
    ...state,
    ...props,
    resources: props.resources ? merge(state.resources, props.resources) : state.resources
  })
);
const initializeI18n = async (resources, locale, fallbackLocale) => {
  return await use(initReactI18next).init({
    resources,
    lng: locale,
    fallbackLng: fallbackLocale,
    interpolation: {
      escapeValue: false
    }
  });
};
function InnerElement({
  children,
  loadingElement
}) {
  const { locale, fallbackLocale } = usePage();
  const logger = useLogger();
  const resources = useStore((s) => s.resources);
  const i18nInstance = useStore((s) => s.i18nInstance);
  const setI18nInstance = useStore((s) => s.setI18nInstance);
  useEffect(() => {
    if (!i18nInstance) {
      initializeI18n(resources, locale, fallbackLocale).then((newInstance) => {
        setI18nInstance(newInstance);
      }).catch((e) => {
        void logger.error(e);
        setI18nInstance(null);
      });
    }
  }, [
    fallbackLocale,
    i18nInstance,
    locale,
    logger,
    resources,
    setI18nInstance
  ]);
  if (!i18nInstance) {
    return loadingElement;
  }
  return children;
}
function I18nProvider({
  children,
  loadingElement,
  ...props
}) {
  return /* @__PURE__ */ jsx(ContextProvider, { ...props, children: /* @__PURE__ */ jsx(InnerElement, { loadingElement, children }) });
}
const useRegisterI18nResources = () => {
  return useStore((state) => state.registerResources);
};
export {
  DEFAULT_RESOURCES,
  I18nProvider,
  initializeI18n,
  useRegisterI18nResources
};
